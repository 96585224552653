export enum Race {
  human = "czlowiek",
  elf = "elf",
  dwarf = "krasnolud",
  ogr = "ogr",
  halfling = "halfling",
  gnome = "gnom",
  halfelf = "polelf",
  unknown = "nieznana",
}
