import { atom } from "jotai";

export const updateSettingsSuccessGlobal = atom(0);
export const restoreBackupSuccessGlobal = atom(0);
export const deleteBackupSuccessGlobal = atom(0);
export const createBackupSuccessGlobal = atom(0);

export const updateEnemySuccessGlobal = atom(0);

export const addKeyGiverDropSuccessGlobal = atom(0);
export const updateKeyGiverDropSuccessGlobal = atom(0);

export const updateKeyGiverSuccessGlobal = atom(0);

export const updateKeySuccessGlobal = atom(0);

export const updateItemSuccessGlobal = atom(0);

export const updateLocationSuccessGlobal = atom(0);
