export const logo = `
                ,,         ,,                                               ,,
      db      \`7MM       \`7MM                                               db
     ;MM:       MM         MM
    ,V^MM.      MM    ,M""bMM   .gP"Ya  \`7Mb,od8  ,6"Yb.  M"""MMV M"""MMV \`7MM
   ,M  \`MM      MM  ,AP    MM  ,M'   Yb   MM' "' 8)   MM  '  AMV  '  AMV    MM
   AbmmmqMA     MM  8MI    MM  8M""""""   MM      ,pm9MM    AMV     AMV     MM
  A'     VML    MM  \`Mb    MM  YM.    ,   MM     8M   MM   AMV  ,  AMV  ,   MM
.AMA.   .AMMA..JMML. \`Wbmd"MML. \`Mbmmd' .JMML.   \`Moo9^Yo.AMMmmmM AMMmmmM .JMML.

                                                                                `;
