export enum Profession {
  barbarian = "barbarzynca",
  fanatic = "fanatyk",
  gladiator = "gladiator",
  corsair = "korsarz",
  legionary = "legionista",
  swordsman = "miecznik",
  cutthroat = "nozownik",
  partisan = "partyzant",
  guardian = "straznik",
  merchant = "kupiec",
  hunter = "mysliwy",
  explorer = "odkrywca",
  unknown = "nieznany",
}
