export enum Level {
  level01 = "niedoswiadczony",
  level02 = "widzial juz to i owo",
  level03 = "pewnie stapa po swiecie",
  level04 = "niejedno widzial",
  level05 = "swoje przezyl",
  level06 = "doswiadczony",
  level07 = "wiele przeszedl",
  level08 = "widzial kawal swiata",
  level09 = "bardzo doswiadczony",
  level10 = "zwiedzil caly swiat",
  level11 = "wielce doswiadczony",
  level12 = "widzial i doswiadczyl wszystkiego",
  level13 = "osoba owiana legenda",
  unknown = "nieznany",
}
