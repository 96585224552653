export enum ItemTypes {
  "weapon" = "bron",
  "armor" = "zbroja",
  "shield" = "tarcza",
  "cloth" = "ubranie",
  "jewellery" = "bizuteria",
  "stone" = "kamien",
  "potion" = "mikstura",
  "other" = "inne",
}
