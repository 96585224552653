export enum Guild {
  ckn = "CKN",
  es = "ES",
  sc = "SC",
  ks = "KS",
  km = "KM",
  os = "OS",
  ohm = "OHM",
  sgw = "SGW",
  bk = "BK",
  wks = "WKS",
  le = "LE",
  kg = "KG",
  kgks = "KGKS",
  mc = "MC",
  ok = "OK",
  ra = "RA",
  gl = "GL",
  zs = "ZS",
  zh = "ZH",
  gp = "GP",
  npc = "NPC",
}
